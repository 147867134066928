import { IEnvVars } from 'shared/utils';

const DEV_ENV_VARS: IEnvVars = {
  //CLIENT_ID: 'asds-rdr7k-qa-rdr7k-webapp-42092d',
  CLIENT_ID: 'Client_594',
  CLIENT_SECRET: 'Asds7000#qa',
  API_ENDPOINT: 'https://rdr-web-exp-api-dev.qaerospace1.honeywell.com',
  ASDS_ENDPOINT: 'https://dasds.honeywell.com:8980/ads/broadcast/message',
  ASDS_ERROR_ENDPOINT:
    'https://stage-aerospace.honeywell.com/en/secure/learn/products/weather-radar/rdr-7000/software-configuration',
  ASDS_GET_HELP: 'https://aerospace.honeywell.com/en/pages/rdr-7k-support',
};

export default DEV_ENV_VARS;
