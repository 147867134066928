export const LABELS = {
  make: 'Make:',
  model: 'Model:',
  stc: 'Cert Number:',
  installMode: 'Install Mode:',
  configModuleSerial: 'Configuration Module Serial #',
  tail: 'Tail #',
  acSerial: 'AC Serial #',
  productNumber: 'Product Number:',
  productDescription: 'Product Description:',
  quantityAvailable: 'Quantity Available:',
  softwareFeatures : 'Software Features:',
  configurationDetails : "Configuration Details",
  purchasedFeatures : "Purchased Features",
  SOFT_COMPATIBALITY_MSG: 'Feature not compatible with selected Install Mode',
  DEFAULT_SOFT_LABEL: 'Default Features',
  PURCHASEBLE_SOFT_LABEL: 'Purchased Features',
  QUANTITY_AVAILABLE : 'Quantity Available:'
};
