import { IEnvVars } from 'shared/utils';

const PROD_ENV_VARS: IEnvVars = {
  //CLIENT_ID: 'asds-rdr7k-prod-rdr7k-webapp-cc3af4',
  CLIENT_ID: 'Client_616',
  CLIENT_SECRET: 'di.1g89h)4fdm(rw',
  API_ENDPOINT: 'https://rdr-web-exp-api.aerospace1.honeywell.com',
  ASDS_ENDPOINT: 'https://ads.honeywell.com/ads/broadcast/message',
  ASDS_ERROR_ENDPOINT:
    'https://aerospace.honeywell.com/en/secure/learn/products/weather-radar/rdr-7000/software-configuration',
  ASDS_GET_HELP: 'https://aerospace.honeywell.com/en/pages/rdr-7k-support',
};

export default PROD_ENV_VARS;
