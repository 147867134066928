import jwt from 'jsonwebtoken';
import { v4 as uuidv4 } from 'uuid';
import { IAppConfig } from './config';

export const getCodeChallenge = () => uuidv4().replace(/-/g, '');

export const decodeToken = (token: string) => jwt.decode(token);

export const getLoginURL = (stateChallenge: string, appConfig: IAppConfig): string => {
  const { authorization_endpoint, client_id, redirect_uri, response_type, scope } = appConfig;

  const loginURL = `${authorization_endpoint}?client_id=${client_id}&response_type=${response_type}&redirect_uri=${redirect_uri}&scope=${scope}&state=${stateChallenge}`;

  return loginURL;
};

export const getLogoutURL = (stateChallenge: string, appConfig: IAppConfig, idToken: string): string => {
  const { end_session_endpoint } = appConfig;

  const logoutURL = `${end_session_endpoint}?id_token_hint=${idToken}&post_logout_redirect_uri=${window.location.origin}/logout&state=${stateChallenge}`;

  return logoutURL;
};
