export interface IAppConfig {
  readonly authorization_endpoint: string;
  readonly token_endpoint: string;
  readonly user_info_endpoint: string;
  readonly end_session_endpoint: string;
  readonly redirect_uri: string;
  readonly client_id: string;
  readonly client_secret: string;
  readonly response_type: string;
  readonly scope: string;
  readonly api_endpoint: string;
  readonly asds_endpoint: string;
  readonly asds_err_endpoint: string;
  readonly asds_get_help_endpoint: string;
}

export interface IEnvVars {
  CLIENT_ID: string;
  CLIENT_SECRET: string;
  API_ENDPOINT: string;
  ASDS_ENDPOINT: string;
  ASDS_ERROR_ENDPOINT: string;
  ASDS_GET_HELP: string;
}

export const DEV_HOSTNAME = 'rdr-webapp-dev.qaerospace1.honeywell.com';
export const QA_HOSTNAME = 'rdr-webapp.qaerospace1.honeywell.com';
export const PROD_HOSTNAME = 'rdr-webapp.aerospace1.honeywell.com';

export const getEnvVariables = (hostName: string = window.location.hostname) => {
  let envEnvVars: IEnvVars;
  let issuerBaseURL: string;

  switch (hostName) {
    case DEV_HOSTNAME:
      envEnvVars = require('./env-dev').default;
      issuerBaseURL = 'https://qauthn.honeywell.com';
      break;
    case QA_HOSTNAME:
      envEnvVars = require('./env-qa').default;
      issuerBaseURL = 'https://qauthn.honeywell.com';
      break;
    case PROD_HOSTNAME:
      envEnvVars = require('./env-prod').default;
      issuerBaseURL = 'https://authn.honeywell.com';
      break;
    default:
      envEnvVars = require('./env-dev').default;
      issuerBaseURL = 'https://qauthn.honeywell.com';
  }

  return { envVars: envEnvVars, issuerBaseURL };
};

const { envVars, issuerBaseURL } = getEnvVariables();

export const appConfig: IAppConfig = {
  authorization_endpoint: `${issuerBaseURL}/as/authorization.oauth2`,
  token_endpoint: `${issuerBaseURL}/as/token.oauth2`,
  user_info_endpoint: `${issuerBaseURL}/idp/userinfo.openid`,
  end_session_endpoint: `${issuerBaseURL}/idp/startSLO.ping`,
  redirect_uri: `${window.location.origin}/authorize`,
  client_id: envVars.CLIENT_ID,
  client_secret: envVars.CLIENT_SECRET,
  response_type: 'code',
  scope: 'openid profile email',
  api_endpoint: envVars.API_ENDPOINT,
  asds_endpoint: envVars.ASDS_ENDPOINT,
  asds_err_endpoint: envVars.ASDS_ERROR_ENDPOINT,
  asds_get_help_endpoint: envVars.ASDS_GET_HELP,
};
