export enum ACCORDION_SECTIONS {
  AircraftDetails = 'aircraftDetails',
  SoftwareFeatures = 'softwareFeatures',
  Both = 'both',
}

export const ACCORDION_TITLES = {
  [ACCORDION_SECTIONS.AircraftDetails]: 'Aircraft Details',
  [ACCORDION_SECTIONS.SoftwareFeatures]: 'Software Features',
};
